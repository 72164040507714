<template>
  <TableRow class="user-table-row">
    <template #content>
      <Accordion>
        <template #heading>
          <div
            class="d-flex align-items-center justify-content-between accordion-header"
          >
            <TableCell size="md">
              <div class="d-flex align-items-center">
                <CountryIcon id="esp" />
                <strong class="ms-2">+34 98 **** ***</strong>
              </div>
            </TableCell>

            <TableCell size="md">
              <strong>Business</strong>
            </TableCell>

            <TableCell size="md">
              <div class="d-flex align-items-center">
                <strong>Spain</strong>
              </div>
            </TableCell>

            <TableCell size="lg">
              <h4>$12.49</h4>
              <p class="secondary-text">
                Credit Card **** 5642
              </p>
            </TableCell>

            <TableCell size="sm">
              <span>-</span>
            </TableCell>

            <TableCell size="md">
              <div class="d-flex justify-content-end">
                <AppBadge
                  :rounded="false"
                  size="md"
                  :type="idx === 1 ? 'secondary' : 'danger'"
                >
                  {{ idx === 1 ? "Active" : "On verification" }}
                </AppBadge>
              </div>
            </TableCell>
          </div>
        </template>
        <template #body>
          <div class="table-accordion-body pt-4">
            <div class="row mb-1">
              <div class="col-3 d-flex">
                <Card title="CurRent PLAN" class="w-100">
                  <p>
                    <strong><b>$4,99 / month</b></strong>
                  </p>

                  <p class="secondary-text pt-1">
                    Purchased on 20.03.2020 at 1:20 am <br />
                    Next renewal on 20.06.2020 at 1:20 am
                  </p>

                  <strong class="mt-3 pt-1 pb-4 mb-3">
                    <b>
                      Monthly subscription
                      <br />with
                      <svg-icon icon="apple" />
                    </b>
                  </strong>

                  <template #footer>
                    <Btn type="secondary" size="md-fluid" :disabled="true">
                      <div class="d-flex align-items-center">
                        <svg-icon icon="plus" class="me-2" />
                        <span>Extend validity</span>
                      </div>
                    </Btn>

                    <Btn
                      size="md-fluid"
                      type="primary-transparent"
                      class="mt-2"
                    >
                      <span class="underline"> Number History</span>
                    </Btn>
                  </template>
                </Card>
              </div>
              <div class="col-3 d-flex">
                <Card title="FEATURES" class="w-100">
                  <Checklist
                    :list="[
                      {
                        text: 'Unlimited incoming Calls, SMS, MMS',
                        checked: true
                      },
                      {
                        text: 'Unlimited voicemail & call recordings',
                        checked: true
                      }
                    ]"
                  />

                  <Checklist
                    :list="[
                      {
                        text: 'Outgoing calls',
                        checked: true
                      },
                      {
                        text: 'Outgoing SMS',
                        checked: true
                      },
                      {
                        text: 'Outgoing MMS',
                        checked: true
                      },
                      {
                        text: 'Outgoing FAX',
                        checked: false
                      }
                    ]"
                  />
                </Card>
              </div>
              <div class="col-3 d-flex">
                <Card title="PACKAGE USAGE" class="w-100">
                  <PackageProgress
                    :total="50"
                    :count="13"
                    type="primary"
                    description="Free SMS sent"
                  />

                  <PackageProgress
                    :total="20"
                    :count="6"
                    type="secondary"
                    description="Free minutes used"
                    class="mt-2"
                  />

                  <template #footer>
                    <Btn type="primary" size="md-fluid">
                      <div class="d-flex align-items-center">
                        <svg-icon icon="plus" class="me-2" />
                        <span>Charge package</span>
                      </div>
                    </Btn>

                    <Btn
                      size="md-fluid"
                      type="primary-transparent"
                      class="mt-2"
                      @click="$emit('openLogsModal', numberId)"
                    >
                      <span class="underline">Usage Logs</span>
                    </Btn>
                  </template>
                </Card>
              </div>
              <div class="col-3 d-flex">
                <NumberActionsCard />
              </div>
            </div>
            <VirtualNumberFooter />
          </div>
        </template>
      </Accordion>
    </template>
  </TableRow>
</template>

<script>
import Accordion from "@/components/ui/Accourdion/Accordion";
import TableRow from "@/components/Table/TableRow";
import TableCell from "@/components/Table/TableCell";
import Card from "@/components/ui/Card/Card";
import NumberActionsCard from "@/components/User/VirtualNumbers/Cards/NumberActionsCard";
import VirtualNumberFooter from "@/components/User/VirtualNumbers/VirtualNumberFooter";
import PackageProgress from "@/components/ui/Progress/PackageProgress";
import Btn from "@/components/ui/Buttons/Button";
import SvgIcon from "@/components/ui/SvgIcon";
import AppBadge from "@/components/ui/AppBadge";
import Checklist from "@/components/ui/Checklist";
import { getId } from "@/utills/getId";
import CountryIcon from "@/components/ui/Country/CountryIcon";
export default {
  name: "VirtualNumbersTableRow",
  components: {
    Accordion,
    TableRow,
    TableCell,
    Btn,
    Card,
    NumberActionsCard,
    VirtualNumberFooter,
    PackageProgress,
    SvgIcon,
    Checklist,
    AppBadge,
    CountryIcon
  },
  props: {
    numberId: { type: String, default: getId("virtual-number") },
    idx: { type: Number, default: 1 } //fixme: Only for test
  },
  emits: ["openLogsModal"]
};
</script>

<style lang="scss">
//.virtual-numbers-list-table {
//  position: relative;
//  transform: translateZ(1px);
//}
//.user-table-row {
//  position: relative;
//  z-index: 99;
//  transform: translateZ(1px);
//}
</style>
