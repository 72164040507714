<template>
  <div
    :class="{
      'status-step': true,
      'd-flex': true,
      'align-items-center': !description && !description.length > 0,
      [`status-step-${currentStatus}`]: true
    }"
  >
    <div class="step-icon">
      <SvgIcon :icon="iconName" />
    </div>

    <div
      class="step-text ms-3 pe-3"
      :class="{
        'pt-2': description && description.length > 0
      }"
    >
      <p>
        <strong>{{ title }}</strong>
        <span v-if="description" class="secondary-text">{{ description }}</span>
      </p>
    </div>
  </div>
</template>

<script>
import SvgIcon from "@/components/ui/SvgIcon";
export default {
  name: "StatusStepItem",
  components: { SvgIcon },
  props: {
    currentStatus: {
      type: String,
      validator: s => ["success", "progress", "error", "wait"].indexOf(s)
    },
    title: { type: String, required: true },
    description: { type: String, required: false }
  },

  computed: {
    iconName() {
      switch (this.currentStatus) {
        case "success":
          return "checkmark";
        case "progress":
          return "progress";
        case "error":
          return "error";
        default:
          return null;
      }
    }
  }
};
</script>

<style scoped lang="scss">
$bg: transparent;
@mixin getColors($color) {
  background-color: rgba($color, 0.1);
  &:before {
    background-color: rgba($color, 0.4);
  }
  &:after {
    background-color: $color;
  }
}
.step-icon {
  width: $progressOuterCircleWidth;
  height: $progressOuterCircleWidth;
  position: relative;
  display: block;
  border-radius: 50%;

  &:before,
  &:after {
    content: "";
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    display: block;
  }

  &:before {
    width: $progressSecondCircleWidth;
    height: $progressSecondCircleWidth;
  }

  &:after {
    width: $progressInnerCircleWidth;
    height: $progressInnerCircleWidth;
  }

  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    color: #fff;
    font-size: 0.5em;
  }
}

.status-step {
  //display: flex;

  &-success {
    .step-icon {
      @include getColors($color-success);
    }
  }
  &-progress {
    .step-icon {
      @include getColors($color-warning);
    }
  }
  &-error {
    .step-icon {
      @include getColors($color-danger);
    }
  }
  &-wait {
    .step-icon {
      width: $progressInnerCircleWidth * 0.59;
      height: $progressInnerCircleWidth * 0.59;
      align-self: flex-end;
      padding-bottom: 0.3em;
      &:before {
        width: $progressInnerCircleWidth * 0.57;
        height: $progressInnerCircleWidth * 0.57;
        background-color: $border-color;
        left: $progressOuterCircleWidth / 2;
      }
    }

    .step-text {
      transform: translateY(0.4em);
      padding-left: $progressOuterCircleWidth -
        ($progressInnerCircleWidth * 0.57);
    }
  }
}
</style>
