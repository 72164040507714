<template>
  <div :class="{ message: true, isMe }">
    <UserInfo
      :avatar="senderPhoto"
      :name="senderName"
      :description="senderRole.name"
    />

    <div class="message-body d-flex align-items-center">
      <p class="message-text">
        <SvgIcon icon="message-arrow" />
        <span>{{ msg }}</span>
      </p>

      <MoreMenu :buttons-list="actions" />
    </div>

    <div class="message-footer">
      {{ time }}
    </div>
  </div>
</template>

<script>
import UserInfo from "@/components/UserInfo/UserInfo";
import MoreMenu from "@/components/Table/MoreMenu";
import SvgIcon from "@/components/ui/SvgIcon";

export default {
  name: "Message",
  components: { MoreMenu, SvgIcon, UserInfo },
  props: {
    msgId: { type: String, required: true },
    isMe: { type: Boolean, default: false },
    msg: { type: String, required: true },
    time: { type: String, required: true },
    senderName: { type: String, required: true },
    senderPhoto: { type: String, required: false },
    senderRole: {
      type: Object,
      required: true,
      validator: role => {
        return "icon" in role && "name" in role;
      }
    },
    actions: {
      type: Array,
      required: true
    }
  }
};
</script>

<style lang="scss">
.message {
  margin-bottom: 30px;
  &:last-child {
    margin-bottom: 20px;
  }
  padding-right: 25px;
  $message-padding-x: 1.64em;
  $message-padding-y: 1em;
  $leftIndent: 20px;
  &-body {
    padding-left: $leftIndent;
    margin-top: 20px;
  }
  &-footer {
    padding-top: 10px;
    padding-left: calc(#{$leftIndent} + #{$message-padding-x});
    color: $grey-100;
    font-size: 12px;
  }
  .user-name {
    font-size: 1em;
    line-height: 1.3;
  }
  .user-description {
    font-size: 0.714em;
  }

  .user-avatar {
    font-size: 3em;
    margin-right: 9px;
  }

  .message-text {
    position: relative;
    background-color: #f5f6fa;
    padding: $message-padding-y $message-padding-x;
    border-radius: 0 $border-radius $border-radius $border-radius;
    max-width: 560px;
    .icon {
      color: #f5f6fa;
      position: absolute;
      top: 0;
      left: 0;
      transform: translateY(-70%);
    }
  }

  &.isMe {
    .message-text {
      background-color: $color-primary-blue;

      .icon {
        color: $color-primary-blue;
      }

      span {
        color: #fff;
        font-size: 1.07em;
      }
    }
  }
}
</style>
