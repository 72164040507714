<template>
  <div :class="`row transaction ${type}`">
    <div class="col-6 d-flex align-items-center">
      <div
        :class="`transaction-icon me-2 transaction-icon--color-${iconColor}`"
      >
        <svg-icon :icon="icon" />
      </div>
      <strong>{{ title }}</strong>
    </div>
    <div class="col-6 d-flex align-items-center justify-content-end">
      <div class="date text-right px-5">
        <strong>{{ date.date }}</strong>
        <span class="secondary-text">{{ date.time }}</span>
      </div>
      <strong class="pe-1 ps-3">
        <b class="amount">{{ amount }}</b>
      </strong>
    </div>
  </div>
</template>

<script>
import SvgIcon from "@/components/ui/SvgIcon";
import { serviceTypesValidator } from "@/components/BalanceHistory/validators";

export default {
  name: "AppTransaction",
  components: { SvgIcon },
  props: {
    type: {
      type: String,
      validator: serviceTypesValidator,
      required: true
    },
    title: { type: String, required: true },

    date: {
      type: Object,
      validator: d => "data" in d && "time" in d,
      required: true
    },

    amount: {
      type: String,
      required: true
    },

    serviceType: {
      type: String,
      validator: serviceTypesValidator,
      default: "incoming"
    }
  },

  computed: {
    icon() {
      return this.serviceType === "sms" ? "msg" : this.serviceType;
    },

    iconColor() {
      return this.serviceType === "package" ? "secondary" : "primary";
    }
  }
};
</script>

<style scoped lang="scss">
.transaction {
  &-icon {
    font-size: 1rem;
    width: 1.57em;
    height: 1.57em;
    min-width: 1.57em;
    min-height: 1.57em;
    @extend %flex-center;
    border-radius: 50%;
    .icon {
      color: #fff;
      font-size: 0.7em;
      margin: 0;
    }
    &--color {
      &-primary {
        background-color: $color-primary-blue;
      }

      &-secondary {
        background-color: $color-success;
      }
    }
  }
}
</style>
