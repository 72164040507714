import { getChartGradient } from "@/utills/charts/styles/gradients";
import {
  primaryGradient,
  secondaryGradient
} from "@/utills/charts/styles/constants";

import { chartTooltipTemplate } from "@/components/User/VirtualNumbers/NumberUsageModal/CallChart/tooltip.tpl";

const colors = [
  getChartGradient(...secondaryGradient),
  getChartGradient(...primaryGradient)
];

export const tooltipConfig = {
  position: "top",
  renderMode: "html",
  className: "chart-tooltip",
  transitionDuration: 0
};

export const legendConfig = legendLabels => ({
  data: legendLabels,
  show: true,
  bottom: 0,
  itemGap: 30,
  itemWidth: 8,
  itemHeight: 8,
  borderRadius: 3
});

export const xAxisStyle = {
  axisLine: {
    lineStyle: {
      color: "#F0F0F0"
    }
  }
};

export const yAxisStyle = {
  axisLine: {
    lineStyle: {
      color: "#828282"
    }
  }
};

export const callsYAxisConfig = {
  type: "value",
  splitNumber: 4,
  interval: 25,
  min: 0,
  scale: true,
  ...yAxisStyle,
  axisLabel: {
    formatter: "{value} min"
  }
};

// values = {
//    name: "",
//    data: []<Number>,
//    callsDuration: ""
// }

export function GetTotalCallsChartConfig(values, legendLabels) {
  return {
    grid: { bottom: 35 },
    xAxis: {
      type: "category",
      boundaryGap: false,
      ...xAxisStyle
    },

    tooltip: {
      ...tooltipConfig,
      formatter: data => {
        return chartTooltipTemplate(
          `${data.data} min`,
          values.find(value => {
            return value.name === data.seriesName;
          })?.callsDuration
        );
      }
    },
    legend: legendConfig(legendLabels),

    yAxis: {
      ...callsYAxisConfig,
      max: 100
    },
    series: values.map((value, idx) => ({
      name: value.name,
      type: "bar",

      data: value.data,
      barWidth: 150,
      barGap: 0.01,
      itemStyle: {
        color: colors[idx],
        borderRadius: [12, 12, 0, 0]
      }
    }))
  };
}
