
import Dialog from "@/components/ui/Modal/Dialog/Dialog.vue";
import AppInput from "@/components/Form/AppInput.vue";
import AppButton from "@/components/ui/Buttons/AppButton.vue";
import AppInputsValidationProvider from "@/components/Form/AppInputsValidationProvider.vue";
import { defineComponent } from "@vue/runtime-core";
const initialValue = () => ({
  amount: 0,
  currency: "USD",
  operationType: "",
  description: "",
  paymentHistoryId: null
});
export default defineComponent({
  name: "EditBalanceDialog",
  components: {
    AppInputsValidationProvider,
    AppButton,
    AppInput,
    Dialog
  },
  inheritAttrs: false,
  props: {
    disabled: { type: Boolean, default: true },
    opened: { type: Boolean, default: false },
    buttonText: { type: String, required: false },
    canCancel: { type: Boolean, default: false },
    editData: { type: Object, required: true },
    loading: { type: Boolean, default: false },
    currentBalance: { type: Number, default: 0 },
    currencySymbol: { type: String, default: "$" }
  },
  emits: ["submit"],
  data() {
    return {
      buttonTextUpdated: this.buttonText || this.title,
      avatar: {} as File,
      isPasswordTouch: false,
      validAmount: false,
      validDesc: false,
      isLoading: false,

      isChecked: false,
      modalValue: initialValue(),
      active: false
    };
  },
  computed: {
    prepareData(): ReturnType<typeof initialValue> {
      const operationType =
        Number(this.currentBalance) > Number(this.modalValue.amount)
          ? "writingoff"
          : "accrual";
      const operationAmount =
        operationType === "writingoff"
          ? Number(this.currentBalance) - Number(this.modalValue.amount)
          : Number(this.modalValue.amount) - Number(this.currentBalance);

            return {
        amount: operationAmount,
        description: this.modalValue.description,
        currency: this.modalValue.currency,
        paymentHistoryId: this.modalValue.paymentHistoryId,
        operationType
      };
    }
  },
  watch: {
    editData: {
      handler(data: any) {
                if (!data) {
          Object.assign(this.modalValue, initialValue());
        } else {
          Object.assign(this.modalValue, data);
        }
      },
      immediate: true
    }
  },
  methods: {
    submit() {
      const data = this.prepareData;
      this.$emit("submit", data);
    },

    hide() {
      this.modalValue = initialValue();
    },

    onChangeErrorAmount(error: any) {
      this.validAmount = !error;
    },
    onChangeErrorDesc(error: any) {
            this.validDesc = !error;
    }
  }
});
