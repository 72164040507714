<template>
  <div class="type-select d-flex">
    <div
      v-for="item in options"
      :key="item.id"
      class="type-select-item"
      :class="{
        'type-select-item--active': item.id === activeTab
      }"
      @click="activeTab = item.id"
    >
      <SvgIcon :icon="item.icon" class="me-2" />
      <span>{{ item.value }}</span>
    </div>
  </div>
</template>

<script>
import SvgIcon from "@/components/ui/SvgIcon";
export default {
  name: "TypeSelect",
  components: { SvgIcon },
  props: { options: { type: Array, required: true } },
  data() {
    return {
      activeTab: this.options[0].id
    };
  }
};
</script>

<style lang="scss">
.type-select-item {
  .icon {
    fill: none;
    stroke: currentColor;
  }
}

.type-select {
  height: $field-height;
  background-color: $color-contrast-light;
  border-radius: $border-radius;
  box-shadow: $shadow-700;
  overflow: hidden;

  &-item {
    flex: 1;
    display: flex;
    align-items: center;
    padding: 5px 20px;
    cursor: pointer;
    transition: 0.25s;
    &--active {
      background-color: $color-contrast-dark;
      color: $color-contrast-light;
      .icon {
        color: inherit;
      }
    }
  }
}
</style>
