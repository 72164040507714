import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EsimProfilesRowHeading = _resolveComponent("EsimProfilesRowHeading")!
  const _component_EsimProfileRowBody = _resolveComponent("EsimProfileRowBody")!
  const _component_Accordion = _resolveComponent("Accordion")!
  const _component_TableRow = _resolveComponent("TableRow")!

  return (_openBlock(), _createBlock(_component_TableRow, { class: "user-table-row" }, {
    content: _withCtx(() => [
      _createVNode(_component_Accordion, null, {
        heading: _withCtx(() => [
          _createVNode(_component_EsimProfilesRowHeading, {
            "profile-name": _ctx.profileName,
            "esim-id": _ctx.esim.ICCID,
            "esim-type": _ctx.esimPackage.dataType.title,
            device: _ctx.device,
            "purchase-date": _ctx.activeFrom,
            status: _ctx.installed
          }, null, 8, ["profile-name", "esim-id", "esim-type", "device", "purchase-date", "status"])
        ]),
        body: _withCtx(() => [
          _createVNode(_component_EsimProfileRowBody, {
            balance: _ctx.balance[0],
            "active-from": _ctx.activeFrom,
            "active-to": _ctx.activeTo,
            "esim-type": _ctx.esimPackage.dataType.title,
            "package-data": _ctx.esimPackage
          }, null, 8, ["balance", "active-from", "active-to", "esim-type", "package-data"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}