<template>
  <div class="verification padding-inner pt-0 d-flex">
    <div class="row w-100">
      <div class="col-8 col-xxl-7 d-flex ">
        <div v-if="step === 1" class="ps-3 ps-xxl-5 ms-xxl-3 align-self-center">
          <h3 class="verification-title">
            This user hasn’t verified ID yet. <br />
            You can manually upload verification details
          </h3>

          <div class="pt-3 mt-4">
            <Btn
              type="secondary"
              size="xl"
              class="verification-button"
              @click="step = 2"
            >
              Start Verification
            </Btn>
          </div>
        </div>

        <Submission v-else-if="step === 2" @submit="step = 3" />
        <VerificationEnd v-else-if="step === 3" />
      </div>
      <div class="col-4 col-xxl-5 position-relative">
        <img
          class="verification-img"
          :src="require('@/assets/img/verification-human.svg')"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
import Btn from "@/components/ui/Buttons/Button";
import Submission from "@/components/User/IdVerification/Submission";
import VerificationEnd from "@/components/User/IdVerification/VerificationEnd";
export default {
  name: "Verification",
  components: { VerificationEnd, Submission, Btn },
  data() {
    return {
      step: 1
    };
  },
  watch: {
    step() {
      localStorage.setItem("verification-step", this.step);
    }
  },
  created() {
    const stepFromStorage = localStorage.getItem("verification-step");

    if (stepFromStorage) {
      this.step = JSON.parse(stepFromStorage);
    }
  }
};
</script>

<style lang="scss">
.position-relative {
  position: relative;
}
.verification {
  min-height: $verificationMinHeight;
}
.verification-img {
  width: 100%;
  height: 370px;
  object-fit: contain;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.verification-button {
  width: 200px;
  max-width: 200px;
}

.verification-title {
  font-weight: 500 !important;
  font-size: 20px;
}
</style>
