<template>
  <Dialog
    class="block-number-modal"
    size="xs"
    :use-header="false"
    v-bind="$props"
  >
    <div class="text-center">
      <h4>
        Contact is blocked for the <br />
        following user’s numbers
      </h4>
    </div>
    <div class="mt-4">
      <SettingsItem
        id="user:blockForAll"
        v-model="blockForAll"
        class="pt-0"
        title="Block for all"
        description="If active, this contact number will not be able to call
    or send messages to all your existing and future virtual numbers "
      />

      <ul class="user-number-list mt-4">
        <li
          v-for="(phone, idx) in phones"
          :key="phone.id"
          class="user-number-list--item"
          :class="{
            'mt-3': idx > 0
          }"
        >
          <label
            :for="phone.id"
            class="d-flex align-items-center justify-content-between"
            :class="{
              pointer: blockForAll
            }"
          >
            <span class="d-flex">
              <CountryIcon :id="phone.countryId" class="me-2" />

              <span class="d-block">
                <strong>{{ phone.number }}</strong>
                <span class="secondary-text">{{ phone.description }}</span>
              </span>
            </span>
            <AppSwitch
              :id="phone.id"
              v-model="phone.checked"
              :disabled="!blockForAll"
            />
          </label>
        </li>
      </ul>

      <div class="mt-5 pb-3">
        <Btn size="xl" type="secondary" @click="$emit('hide')">Save</Btn>
        <Btn size="xl" type="default-transparent" @click="$emit('hide')">
          Cancel
        </Btn>
      </div>
    </div>
  </Dialog>
</template>

<script>
import Dialog from "@/components/ui/Modal/Dialog/Dialog";
import SettingsItem from "@/components/Settings/SettingsItem";
import AppSwitch from "@/components/Form/AppSwitch";
import CountryIcon from "@/components/ui/Country/CountryIcon";
import Btn from "@/components/ui/Buttons/Button";
export default {
  name: "BlockNumberManageDialog",
  components: { Btn, CountryIcon, AppSwitch, SettingsItem, Dialog },
  data() {
    return {
      blockForAll: true,

      phones: [
        {
          id: "phone-1",
          checked: true,
          number: "+34 88 7867 121 ",
          description: "Home",
          countryId: "esp"
        },
        {
          id: "phone-2",
          checked: true,
          number: "+1 092 6784 887",
          description: "Business",
          countryId: "usa"
        }
      ]
    };
  }
};
</script>

<style lang="scss">
.block-number-modal {
  .settings-item {
    border: none;
  }
  &.modal {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .modal-body {
    padding: 2em 25px 0 25px !important;
  }
}
</style>
