import { useStore } from "vuex";
import { CreateNoteDto } from "@/store/interfaces/notes/CreateNote.dto";
import { appStorage } from "@/storage/storage";
export interface notesQuery {
  hold: boolean;
  take: number;
  skip: number;
}
interface Options {
  query: notesQuery;
  id: number;
}
export function useNotes(options: Options) {
  const store = useStore();
  const { query, id } = options;
  async function getNotes() {
    // await store.dispatch("setAppLoading", true);
    const { success, message } = await store.dispatch("fetchNotesList", {
      query,
      id
    });
    if (!success) {
      await store.dispatch("showErrorNotification", message);
    }
    // await store.dispatch("setAppLoading", false);
  }
  async function getHoldNotes() {
    // await store.dispatch("setAppLoading", true);
    const { success, message } = await store.dispatch("fetchHoldNotesList", {
      query,
      id
    });
    if (!success) {
      await store.dispatch("showErrorNotification", message);
    }
    // await store.dispatch("setAppLoading", false);
  }
  async function addNote(data: CreateNoteDto) {
    // await store.dispatch("setAppLoading", true);
    const { success, message } = await store.dispatch("addNotes", {
      id,
      data
    });
    if (!success) {
      await store.dispatch("showErrorNotification", message);
    }
    // await store.dispatch("setAppLoading", false);
    return success;
  }
  async function changeNoteStatus(id: number, noteId: number, status: boolean) {
    // await store.dispatch("setAppLoading", true);
    const { success, message } = await store.dispatch("changeStatusNotes", {
      id,
      noteId,
      data: {
        hold: status
      }
    });
    if (!success) {
      await store.dispatch("showErrorNotification", message);
    }
    // await store.dispatch("setAppLoading", false);
    return success;
  }
  async function removeNote(id: number, noteId: number) {
    // await store.dispatch("setAppLoading", true);
    const { success, message } = await store.dispatch("removeNote", {
      id,
      noteId
    });
    if (!success) {
      await store.dispatch("showErrorNotification", message);
    }
    // await store.dispatch("setAppLoading", false);
    return success;
  }
  return {
    getNotes,
    getHoldNotes,
    addNote,
    changeNoteStatus,
    removeNote
  };
}
