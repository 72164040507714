
import TableCell from "@/components/Table/TableCell.vue";
import { defineComponent } from "@vue/runtime-core";
import AppBadge from "@/components/ui/AppBadge.vue";
import { formateLLTime, formateLTTime } from "@/hooks/formatingDate";

export default defineComponent({
  name: "EsimProfilesRowHeading",
  components: { AppBadge, TableCell },
  props: {
    profileName: {
      type: String,
      required: true
    },
    esimId: {
      type: String,
      required: true
    },
    esimType: {
      type: String,
      required: true
    },
    device: {
      type: String,
      required: true
    },
    purchaseDate: {
      type: String,
      required: true
    },
    status: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    formatFirstTime() {
      return formateLLTime(this.purchaseDate);
    },
    formatSecondTime() {
      return formateLTTime(this.purchaseDate);
    }
  }
});
