import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "d-flex align-items-center" }
const _hoisted_3 = { class: "me-4 pe-1" }
const _hoisted_4 = {
  key: 0,
  class: "container loader-container"
}
const _hoisted_5 = { class: "page-body mt-4 pt-3" }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "col-6" }
const _hoisted_8 = { class: "mt-4" }
const _hoisted_9 = { class: "mt-4" }
const _hoisted_10 = { class: "shadow-600 border-radius-1" }
const _hoisted_11 = { class: "col-6" }
const _hoisted_12 = { class: "col-12" }
const _hoisted_13 = { class: "mt-4 pt-2" }
const _hoisted_14 = { class: "col-12" }
const _hoisted_15 = { class: "mt-4 pt-2" }
const _hoisted_16 = { class: "col-12" }
const _hoisted_17 = { class: "mt-4 pt-2" }
const _hoisted_18 = { class: "row pb-5 mb-5" }
const _hoisted_19 = { class: "col-6 mt-4 d-flex" }
const _hoisted_20 = { class: "col-6 mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NumberUsageModal = _resolveComponent("NumberUsageModal")!
  const _component_DeleteUserDialog = _resolveComponent("DeleteUserDialog")!
  const _component_BackButton = _resolveComponent("BackButton")!
  const _component_FullscreenLoader = _resolveComponent("FullscreenLoader")!
  const _component_UserBalance = _resolveComponent("UserBalance")!
  const _component_AccountStatus = _resolveComponent("AccountStatus")!
  const _component_AccountInfo = _resolveComponent("AccountInfo")!
  const _component_AppCollapsable = _resolveComponent("AppCollapsable")!
  const _component_NotesBlock = _resolveComponent("NotesBlock")!
  const _component_AppSecurity = _resolveComponent("AppSecurity")!
  const _component_VirtualNumbersTable = _resolveComponent("VirtualNumbersTable")!
  const _component_InDeveloping = _resolveComponent("InDeveloping")!
  const _component_EsimProfilesTable = _resolveComponent("EsimProfilesTable")!
  const _component_PaymentHistoryTable = _resolveComponent("PaymentHistoryTable")!
  const _component_TranslationPackages = _resolveComponent("TranslationPackages")!
  const _component_NewsletterPreferences = _resolveComponent("NewsletterPreferences")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_NumberUsageModal, {
      opened: !!_ctx.usageLogsActiveModalId,
      onHide: _ctx.hideUsageLogsModal
    }, null, 8, ["opened", "onHide"]),
    _createVNode(_component_DeleteUserDialog, {
      opened: _ctx.deleteUserDialogShown,
      "user-email": _ctx.accountInfo?.email,
      "user-name": _ctx.accountInfo?.fullName,
      "user-photo": _ctx.accountInfo?.image,
      loading: _ctx.isLoading,
      onHide: _ctx.cleanData,
      onCancel: _ctx.cleanData,
      onConfirm: _ctx.removeUserHandler
    }, null, 8, ["opened", "user-email", "user-name", "user-photo", "loading", "onHide", "onCancel", "onConfirm"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_BackButton)
        ]),
        _createElementVNode("h1", null, _toDisplayString(_ctx.userName), 1)
      ]),
      (_ctx.useLoader && _ctx.appLoading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("div", null, [
              _createVNode(_component_FullscreenLoader)
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", null, [
              _createVNode(_component_UserBalance, {
                balance: _ctx.userBalance,
                "currency-symbol": "$"
              }, null, 8, ["balance"])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_AccountStatus, {
                "blocked-user": _ctx.blocked,
                "user-id": _ctx.userId,
                "email-verified": _ctx.emailVerified,
                onDelete: _ctx.onUserDelete
              }, null, 8, ["blocked-user", "user-id", "email-verified", "onDelete"])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_AppCollapsable, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_AccountInfo, {
                      "user-type": 'user',
                      "user-id": _ctx.userId,
                      "user-information": _ctx.userInformation,
                      onUploadAvatar: _ctx.uploadAvatar,
                      onEditUser: _ctx.editUser
                    }, null, 8, ["user-id", "user-information", "onUploadAvatar", "onEditUser"])
                  ]),
                  _: 1
                })
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_component_NotesBlock, {
              "format-date": _ctx.formatDate,
              "group-by-date": _ctx.groupByDate,
              "notes-data": _ctx.notesList,
              "notes-total": _ctx.totalNotes,
              "hold-notes-data": _ctx.notesHoldList,
              "hold-notes-total": _ctx.totalNotesHold,
              onCreateNote: _ctx.createNote,
              onChangeNoteStatus: _ctx.changeNoteStatusHandler,
              onRemoveNote: _ctx.removeNoteHandler
            }, null, 8, ["format-date", "group-by-date", "notes-data", "notes-total", "hold-notes-data", "hold-notes-total", "onCreateNote", "onChangeNoteStatus", "onRemoveNote"]),
            _createVNode(_component_AppSecurity, { "login-history": _ctx.userLogins }, null, 8, ["login-history"])
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, [
              _createVNode(_component_InDeveloping, null, {
                default: _withCtx(() => [
                  _createVNode(_component_VirtualNumbersTable, { onOpenLogsModal: _ctx.showUsageLogsModal }, null, 8, ["onOpenLogsModal"])
                ]),
                _: 1
              })
            ])
          ]),
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("div", _hoisted_15, [
              _createVNode(_component_EsimProfilesTable, { "user-id": _ctx.userId }, null, 8, ["user-id"])
            ])
          ]),
          _createElementVNode("div", _hoisted_16, [
            _createElementVNode("div", _hoisted_17, [
              _createVNode(_component_PaymentHistoryTable)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_18, [
          _createElementVNode("div", _hoisted_19, [
            _createVNode(_component_InDeveloping, null, {
              default: _withCtx(() => [
                _createVNode(_component_TranslationPackages, { class: "w-100" })
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_20, [
            _createVNode(_component_InDeveloping, null, {
              default: _withCtx(() => [
                _createVNode(_component_NewsletterPreferences)
              ]),
              _: 1
            })
          ])
        ])
      ])
    ])
  ], 64))
}