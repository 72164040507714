<template>
  <ul class="app-checklist">
    <li v-for="{ text, description, checked } in list" :key="text">
      <div
        :class="{
          'd-flex': true,
          checked: checked,
          'checklist-item': true
        }"
      >
        <svg-icon :icon="checked ? 'checkmark' : 'tick'" class="mb-0 mt-1" />
        <div class="ms-2">
          <strong class="d-block">{{ text }}</strong>
          <span v-if="description" class="secondary-text">
            {{ description }}
          </span>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
// item = {
//   text: String,
//   description?: String,
//   checked: Boolean
// }

import SvgIcon from "@/components/ui/SvgIcon";
export default {
  name: "Checklist",
  components: { SvgIcon },
  props: {
    list: { type: Array, required: true }
  }
};
</script>

<style scoped lang="scss">
.app-checklist {
  li {
    padding-bottom: 11px;
  }
  .checklist-item {
    font-size: 13px;
    color: $grey-100;
    .icon {
      color: $color-danger;
    }

    &.checked {
      color: $color-contrast-dark;
      .icon {
        color: $color-success;
      }
    }
  }

  + .app-checklist {
    padding-top: 20px;
    border-top: 1px solid $border-color;
  }
}
</style>
