export function chartTooltipTemplate(title, callsDuration) {
  return `
    <div class="chart-tooltip-content">
      <span class="text-center small-text tooltip-body">
       ${callsDuration ? `<b>${title}</b>` : `<span>${title}</span>`} 
        
        ${
          callsDuration
            ? `<span class="grey-300">
                ${callsDuration} min average call duration
              </span> `
            : ""
        }
              
      </span>        
      
        <span class="tooltip-arrow"></span>      
    </div>
      
  `;
}
