<template>
  <div class="upload-area d-block" :data-disabled="file !== ''">
    <input
      :id="id"
      ref="field"
      :accept="accept"
      hidden
      type="file"
      @change="changeHandler"
    />

    <span class="text-center d-block">
      <strong>
        <b>{{ title }}</b>
      </strong>
    </span>
    <span class="secondary-text d-block text-center">{{ description }}</span>

    <div class="d-flex justify-content-center w-100 mt-4">
      <FileItem v-if="file" :name="file.name" @remove="removeHandler()" />

      <Btn v-else type="primary-border" size="md" @click="showFileMenu">
        Upload File
      </Btn>
    </div>
  </div>
</template>

<script>
import { getId } from "@/utills/getId";
import Btn from "@/components/ui/Buttons/Button";
import FileItem from "@/components/Form/Files/FileItem";

export default {
  name: "UploadArea",
  components: { FileItem, Btn },
  emits: ["upload"],
  props: {
    id: { type: String, default: getId(":uploader") },
    title: { type: String, required: true },
    accept: { type: String, default: "" },
    description: {
      type: String,
      default:
        "Can be electric bill, gas, phone, bank. File type – PNG, JPEG or PDF. Size limit - 2Mb."
    }
  },

  data() {
    return { file: "" };
  },

  computed: {
    field() {
      return this.$refs.field ?? null;
    }
  },
  methods: {
    removeHandler() {
      this.file = "";
      this.$emit("upload", null);
    },

    showFileMenu() {
      if (this.field) {
        this.field.click();
      }
    },
    changeHandler({ target }) {
      if (target.files && target.files[0]) {
        this.file = target.files[0];
        this.$emit("upload", this.file);
      } else {
        this.$emit("upload", null);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.upload-area {
  border: 1px dashed $border-color;
  background-color: rgba($grey-400, 0.5);
  padding: 20px 30px;
  border-radius: $border-radius;
}
</style>
