
import AppCollapsable from "@/components/ui/Collapsable/AppCollapsable.vue";
import SelectDropdown from "@/components/ui/Dropdown/SelectDropdown.vue";
import TableView from "@/components/Table/TableView.vue";
import TableHeadCell from "@/components/Table/TableHeadCell.vue";
import PaymentHistoryRow from "@/components/User/PaymentHistory/PaymentHistoryRow.vue";
import TableFooter from "@/components/Table/TableFooter.vue";
import { useCollapsedWithAccordion } from "@/mixins/useCollapsedWithAccordion";
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
  name: "PaymentHistoryTable",
  components: {
    TableFooter,
    PaymentHistoryRow,
    TableHeadCell,
    SelectDropdown,
    AppCollapsable,
    TableView
  },
  mixins: [useCollapsedWithAccordion],
  data: () => ({
    appleIcon: `
        <?xml version="1.0" encoding="iso-8859-1"?>
          <svg width="1rem" height="1rem" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
          \t viewBox="0 0 512.003 512.003" style="enable-background:new 0 0 512.003 512.003;" xml:space="preserve">
          <g>
          \t<g>
          \t\t<path d="M351.98,0c-27.296,1.888-59.2,19.36-77.792,42.112c-16.96,20.64-30.912,51.296-25.472,81.088
          \t\t\tc29.824,0.928,60.64-16.96,78.496-40.096C343.916,61.568,356.556,31.104,351.98,0z"/>
          \t</g>
          </g>
          <g>
          \t<g>
          \t\t<path d="M459.852,171.776c-26.208-32.864-63.04-51.936-97.824-51.936c-45.92,0-65.344,21.984-97.248,21.984
          \t\t\tc-32.896,0-57.888-21.92-97.6-21.92c-39.008,0-80.544,23.84-106.88,64.608c-37.024,57.408-30.688,165.344,29.312,257.28
          \t\t\tc21.472,32.896,50.144,69.888,87.648,70.208c33.376,0.32,42.784-21.408,88-21.632c45.216-0.256,53.792,21.92,87.104,21.568
          \t\t\tc37.536-0.288,67.776-41.28,89.248-74.176c15.392-23.584,21.12-35.456,33.056-62.08
          \t\t\tC387.852,342.624,373.932,219.168,459.852,171.776z"/>
          \t</g>
          </g>
          </svg>

      `,
    paymentStatuses: [
      {
        id: "esim:Success",
        name: "Success",
        selected: false
      },
      {
        id: "esim:Refunded",
        name: "Refunded",
        selected: false
      },
      {
        id: "esim:Rejected",
        name: "Rejected",
        selected: false
      }
    ] as Object[]
  }),
  methods: {
    onPaymentStatusFiler(id: number) {
      this.paymentStatuses.forEach((t: any) => (t.selected = t.id === id));
    }
  }
});
