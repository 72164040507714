import {
  serviceCall,
  servicePackage,
  serviceSms,
} from "@/constants/serviceTypes";
import moment from "moment";
interface IBalancesHistory {
  id: number;
  amount: number;
  createdAt: string;
  description: string;
  operationType: "writingoff" | "accrual";
  paymentHistory: { product: Object };
  balanceAfter: number;
}
export function useBalanceHelper() {
  function getProps(selectedId: number, balanceHistory: IBalancesHistory[]) {
    const {
      id,
      amount,
      createdAt,
      description,
      operationType,
      paymentHistory,
      balanceAfter,
    } = balanceHistory?.find((item) => item?.id === selectedId) || {};
    const type = operationType === "writingoff" ? "outgoing" : "incoming";
    const date = moment(createdAt).format("D MMM YYYY [at] HH[:]mm a");
    const selectedDescription = description
      ? description
      : type === "outgoing"
      ? "Write-off of bonuses for payment of the package"
      : "Accrual of bonuses for the purchase of the package";
    const transactionTitle =
      //@ts-ignore
      selectedDescription + ` '${paymentHistory?.product?.profileName || ""}'`;

    return {
      serviceType: servicePackage,
      transactionDirection: "",
      transactionTitle,
      date,
      transactionId: id,
      amount: "$" + amount,
      type,
      balanceAfterTransaction: "$" + balanceAfter,
    };
  }
  function formatTimestamp(timestampFromPostgres: string) {
    const dateObject = moment(timestampFromPostgres);
    const formattedDate = {
      date: dateObject.format("D MMMM YYYY"),
      time: `at ${dateObject.format("h:mm a")} GMT`,
    };
    return formattedDate;
  }

  function getMapedHistory(balanceHistory: IBalancesHistory[]) {
    //@ts-ignore
    balanceHistory.value?.map(
      ({
        id,
        amount,
        createdAt,
        description,
        operationType,
        paymentHistory,
        ...transaction
      }: IBalancesHistory) => {
        const serviceType = servicePackage;
        const type = operationType === "writingoff" ? "outgoing" : "incoming";
        const date = formatTimestamp(createdAt);
        description = description
          ? description
          : type === "outgoing"
          ? "Write-off of bonuses for payment of the package"
          : "Accrual of bonuses for the purchase of the package";
        const title =
          //@ts-ignore
          description + ` '${paymentHistory?.product?.profileName || ""}'`;
        return {
          id,
          title,
          amount: "$" + amount,
          date,
          ...transaction,
          serviceType,
          type,
        };
      }
    );
  }
  return {
    getProps,
    formatTimestamp
  };
}
