import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mt-3 pt-1" }
const _hoisted_2 = { class: "mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppInput = _resolveComponent("AppInput")!
  const _component_AppInputsValidationProvider = _resolveComponent("AppInputsValidationProvider")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, _mergeProps({
    title: 'Edit user balance',
    opened: _ctx.opened
  }, _ctx.$attrs, { onHide: _ctx.hide }), {
    default: _withCtx(() => [
      _createElementVNode("form", {
        onSubmit: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"]))
      }, [
        _createElementVNode("div", null, [
          _createVNode(_component_AppInputsValidationProvider, {
            "shake-tree": true,
            onChangeErrorState: _ctx.onChangeErrorAmount
          }, {
            default: _withCtx(() => [
              _createVNode(_component_AppInput, {
                id: "create-user-name",
                modelValue: _ctx.modalValue.amount,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modalValue.amount) = $event)),
                "input-type": "material",
                label: "Balance amount",
                type: "text",
                "validation-type": "number"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }, 8, ["onChangeErrorState"]),
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_AppInputsValidationProvider, { onChangeErrorState: _ctx.onChangeErrorDesc }, {
              default: _withCtx(() => [
                _createVNode(_component_AppInput, {
                  id: "create-user-desc",
                  modelValue: _ctx.modalValue.description,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.modalValue.description) = $event)),
                  "input-type": "material",
                  label: "Description",
                  type: "text",
                  "validation-type": "name"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["onChangeErrorState"])
          ])
        ]),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_AppButton, {
            disabled: !_ctx.validAmount || !_ctx.validDesc || _ctx.prepareData.amount === 0,
            loading: _ctx.loading,
            type: "secondary",
            size: "xl",
            onClick: _ctx.submit
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Save ")
            ]),
            _: 1
          }, 8, ["disabled", "loading", "onClick"])
        ])
      ], 32)
    ]),
    _: 1
  }, 16, ["opened", "onHide"]))
}