<template>
  <AppCollapsable
    collapsed-height="880"
    :initial-opened="!collapsed"
    @open="collapseToggle(false)"
    @close="collapseToggle(true)"
  >
    <div class="content-box px-0 pb-0 user-table-wrapper">
      <div class="d-flex align-items-center padding-inner py-0">
        <h2>Virtual Numbers</h2>
        <div class="ps-4 ms-2">
          <AppBadge size="md" type="secondary">
            <div class="d-flex virtual-nums-badge">
              <svg-icon icon="credits" class="me-2" />
              <span>$ <b>87.50</b> credits</span>
            </div>
          </AppBadge>
        </div>
      </div>

      <div class="mt-3 pt-2">
        <div>
          <tabs v-model="selectedTab" class="padding-inner py-0">
            <tab
              v-for="(tab, i) in tabs"
              :key="`t${i}`"
              class="tab "
              :val="tab"
              :label="tab"
              :indicator="true"
            />
          </tabs>
        </div>

        <div class="pt-4 mt-3 overlay-overflow">
          <tab-panels v-model="selectedTab" :animate="false">
            <tab-panel :val="tabs[0]">
              <div v-if="selectedTab === tabs[0]" class="w-100">
                <VirtualNumbersList
                  @openLogsModal="numberId => $emit('openLogsModal', numberId)"
                />
              </div>
            </tab-panel>

            <tab-panel :val="tabs[1]">
              <div v-if="selectedTab === tabs[1]" class="w-100">
                <VirtualNumberFavorites />
              </div>
            </tab-panel>
            <tab-panel :val="tabs[2]">
              <div v-if="selectedTab === tabs[2]" class="w-100">
                <BlockedNumbersList />
              </div>
            </tab-panel>
            <tab-panel :val="tabs[3]">
              <div v-if="selectedTab === tabs[3]" class="w-100">
                <CallLogs />
              </div>
            </tab-panel>
            <tab-panel :val="tabs[4]">
              <div v-if="selectedTab === tabs[4]" class="w-100">
                <SmsLogs />
              </div>
            </tab-panel>
            <tab-panel :val="tabs[5]">
              <div v-if="selectedTab === tabs[5]" class="w-100">
                <FaxLogs />
              </div>
            </tab-panel>
            <tab-panel :val="tabs[6]">
              <div v-if="selectedTab === tabs[6]" class="w-100">
                <Verification />
              </div>
            </tab-panel>
          </tab-panels>
        </div>
      </div>
    </div>
  </AppCollapsable>
</template>

<script>
import AppBadge from "@/components/ui/AppBadge";
import SvgIcon from "@/components/ui/SvgIcon";
import AppCollapsable from "@/components/ui/Collapsable/AppCollapsable";
import VirtualNumbersList from "@/components/User/VirtualNumbers/VirtualNumbersList";
import VirtualNumberFavorites from "@/components/User/VirtualNumbers/VirtualNumberFavorites";
import BlockedNumbersList from "@/components/User/BlockUser/BlockedNumbersList";
import CallLogs from "@/components/User/LogsTables/CallLogs";
import SmsLogs from "@/components/User/LogsTables/SmsLogs";
import FaxLogs from "@/components/User/LogsTables/FaxLogs";
import Verification from "@/components/User/IdVerification/Verification";
import { useCollapsedWithAccordion } from "@/mixins/useCollapsedWithAccordion";

export default {
  name: "VirtualNumbersTable",
  components: {
    Verification,
    FaxLogs,
    SmsLogs,
    CallLogs,
    BlockedNumbersList,
    VirtualNumberFavorites,
    VirtualNumbersList,
    AppCollapsable,
    SvgIcon,
    AppBadge
  },
  mixins: [useCollapsedWithAccordion],
  emits: ["openLogsModal"],

  data() {
    return {
      resizeHandleTimeout: null,
      tabs: [
        "List",
        "Favourites",
        "Blocked Numbers",
        "Call Logs",
        "SMS/MMS Logs",
        "FAX Logs",
        "ID Verification"
      ],
      selectedTab: "List"
    };
  }
};
</script>

<style lang="scss">
.user-table-wrapper {
  position: relative;
  overflow: hidden;
  .pseudo-table {
    min-height: 350px;
  }

  .verification {
    margin-top: -3rem;
    min-height: calc(#{$verificationMinHeight} + 3rem);
  }
}
</style>
