
import Card from "@/components/ui/Card/Card.vue";
import Btn from "@/components/ui/Buttons/Button.vue";
import Country from "@/components/ui/Country/Country.vue";
import Checklist from "@/components/ui/Checklist.vue";
import PackageProgress from "@/components/ui/Progress/PackageProgress.vue";
import { getId } from "@/utills/getId";
import { defineComponent } from "@vue/runtime-core";
import SettingsItem from "@/components/Settings/SettingsItem.vue";
import PackageDataProgress from "@/components/ui/Progress/PackageDataProgress.vue";
import { PropType } from "vue";
import { IEsimBalance } from "@/api/services/esim/profile/profile.types";
import { IPackage } from "@/api/services/packages/esim/location-packages/types";
import { formatDate } from "@/hooks/formatingDate";
import EsimProfilesPackageBlock from "@/components/User/eSIMProfiles/EsimProfilesPackageBlock.vue";

export default defineComponent({
  name: "EsimProfileRowBody",
  components: {
    EsimProfilesPackageBlock,
    PackageDataProgress,
    SettingsItem,
    PackageProgress,
    Checklist,
    Country,
    Btn,
    Card
  },
  props: {
    validTill: {
      type: String,
      default: "Valid till 20.03.2020 <br/> at 1:20 am GMT"
    },
    esimType: {
      type: String,
      default: "Data only"
    },
    balance: {
      type: Object as PropType<IEsimBalance>,
      required: true
    },
    activeFrom: {
      type: String,
      required: true
    },
    activeTo: {
      type: String,
      required: true
    },
    packageData: {
      type: Object as PropType<IPackage>,
      required: true
    }
  },
  data() {
    return {
      id: getId(),
      freeSocials: true,
      planOptions: [
        {
          id: "planOption: tourist",
          name: "Tourist",
          selected: true,
          html: this.getSelectElementHtml("Tourist")
        }
        // {
        //   id: "planOption: Premium",
        //   name: "Tourist 10 GB - 14 Days",
        //   selected: false,
        //   html: this.getSelectElementHtml("Premium")
        // },
        // {
        //   id: "planOption: Business",
        //   name: "Tourist 10 GB - 14 Days",
        //   selected: false,
        //   html: this.getSelectElementHtml("Business")
        // }
      ],
      usageInfo:
        this.esimType === "withNumber"
          ? [
              {
                id: "usage:traffic",
                count: 1.2,
                total: 5,
                description: "Available GBs left",
                type: "primary"
              },
              {
                id: "usage:sms",
                count: 9,
                total: 20,
                description: "Free SMS left",
                type: "secondary"
              },
              {
                id: "usage:minutes",
                count: 48,
                total: 50,
                description: "Free minutes left",
                type: "secondary"
              }
            ]
          : [
              {
                id: "usage:data",
                count: Number(
                  this.balance.remaining ? this.balance.remaining : 0
                ).toFixed(),
                total: Number(
                  this.balance.total ? this.balance.total : 10
                ).toFixed(),
                description: "Free minutes left",
                type: "secondary",
                unit: "GB"
              }
            ]
      // coverageRegions: [
      //   { text: "NOS", description: "2G, 3G, 4G/LTE, 5G", checked: true },
      //   { text: "MEO", description: "4G/LTE, 5G", checked: true },
      //   { text: "Vodafone", description: "3G/4G/LTE, 5G", checked: true }
      // ]
    };
  },
  computed: {
    getFormatDateActiveFrom() {
      return formatDate(this.activeFrom);
    },
    validitiTill() {
      return this.activeTo
        ? `Valid till ${formatDate(this.activeTo)} GMT`
        : "Esim not activated...";
    },
    getBalanceCount() {
      const temp = Number(this.balance.remaining) / 1024;
      return Number(temp.toFixed());
    },
    getBalanceTotal() {
      const temp = Number(this.balance.total) / 1024;
      return Number(temp.toFixed());
    },
    coverageRegions() {
      return (
        this.packageData?.operators?.map((op: any) => {
          return {
            text: op.name,
            description: op.networkGenerations.reduce(
              (acc: string, n: any, i: number) => {
                if (!acc) acc = "";
                return (acc += (i !== 0 ? "/" : "") + n.title);
              },
              ""
            ),
            checked: true
          };
        }) || []
      );
    },
    packageLocation() {
      return this.packageData?.country
        ? this.packageData.country
        : this.packageData?.region
        ? this.packageData.region
        : this.packageData?.subregion;
    }
  },
  methods: {
    getSelectElementHtml(pack: string) {
      console.log(this.esimType);
      console.log(this.balance);
      console.log(this.activeFrom);
      console.log(this.activeTo);
      console.log(this.packageData);
      if (this.esimType === "DATA only") {
        return `
             <strong><b class="single">${this.packageData?.plan?.name} </b>${this.packageData.title} - ${this.packageData?.validity?.title}</strong>
          `;
      }

      return `
          <div>
            <strong><span class="d-flex align-items-center option-inner"><b class="me-0">${pack} </b>(14 Days)</span></strong>
            <strong>15 GB - 150 min - 25 sms </strong>
          </div>
      `;
    }
  }
});
