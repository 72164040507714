
import TableView from "@/components/Table/TableView.vue";
import TableHeadCell from "@/components/Table/TableHeadCell.vue";
import AppCollapsable from "@/components/ui/Collapsable/AppCollapsable.vue";
import EsimProfilesTableRow from "@/components/User/eSIMProfiles/EsimProfilesTableRow.vue";
import SelectDropdown from "@/components/ui/Dropdown/SelectDropdown.vue";
import { useCollapsedWithAccordion } from "@/mixins/useCollapsedWithAccordion";
import AppTableController from "@/components/Table/ts/AppTableController.vue";
import { computed, ComputedRef, defineComponent } from "vue";
import { ITableHeadCell } from "@/components/Table/ts/interfaces/TableStructure";
import { useUserEsimProfile } from "@/hooks/esim/user-profile/useUserEsimProfile";
import { useStore } from "vuex";
type tableHeadCells = ITableHeadCell[];

export default defineComponent({
  name: "EsimProfilesTable",
  components: {
    AppTableController,
    SelectDropdown,
    EsimProfilesTableRow,
    AppCollapsable,
    TableHeadCell,
    TableView
  },
  mixins: [useCollapsedWithAccordion],
  props: {
    userId: {
      type: Number,
      required: true
    }
  },
  setup(props) {
    const store = useStore();
    const { fetchUserEsimProfile } = useUserEsimProfile();
    const userId: ComputedRef<number> = computed(() => props.userId);
    fetchUserEsimProfile(userId.value);
    return {
      esimProfilesList: computed(() => store.getters.esimProfilesList)
    };
  },
  data() {
    return {
      esimStatuses: [
        {
          id: "esim:Installed",
          name: "installed",
          selected: false
        },
        {
          id: "esim:Not-installed",
          name: "Not installed",
          selected: false
        }
      ],
      loading: false
    };
  },
  computed: {
    isLoading() {
      return false;
    },
    tableHead(): tableHeadCells {
      return [
        {
          size: "fluid",
          label: "LABEL",
          id: "profile-label"
        },

        {
          size: "lg",
          label: "ID",
          id: "esim-id"
        },
        {
          size: "md",
          label: "ESIM TYPE",
          id: "esim-type"
        },
        {
          size: "md",
          label: "DEVICE",
          id: "profile-device"
        },
        {
          size: "md",
          label: "PURCHASE DATE",
          id: "purchase-date"
        },
        {
          size: "fluid",
          label: "STATUS",
          id: "profile-status"
        }
      ];
    }
  },
  methods: {
    onEsimStatusFiler(id: number | string) {
      this.esimStatuses.forEach(t => (t.selected = t.id === id));
    }
  }
});
