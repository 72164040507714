<template>
  <div class="status-steps">
    <ul class="status-steps-list">
      <li v-for="{ status, title, description = '' } in stepsList" :key="title">
        <StatusStepItem
          :current-status="status"
          :title="title"
          :description="description"
        />
      </li>
    </ul>
  </div>
</template>

<script>
/* step = {
   title: "",
   description?: "",
   status: "success" | "progress" | "error" | "wait"
}*/

import StatusStepItem from "@/components/ui/Statuses/StatusStepItem";
export default {
  name: "StatusSteps",
  components: { StatusStepItem },
  props: {
    stepsList: {
      type: Array,
      required: true
    }
  }
};
</script>

<style scoped lang="scss">
.status-steps {
  position: relative;
  $indent: ($progressOuterCircleWidth - $progressInnerCircleWidth) / 2;
  .status-steps-list {
    position: relative;
    z-index: 2;
    transform: translateY(-#{$indent});
    & > li {
      &:not(:first-child) {
        margin-top: 40px;
      }
    }
  }
  &:before {
    content: "";
    width: 1px;
    height: calc(100% - #{$indent});
    background-color: $border-color;
    position: absolute;
    top: 0;
    left: $progressOuterCircleWidth / 2;
    z-index: 2;
  }
}
</style>
