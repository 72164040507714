import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-100 d-flex align-items-center justify-content-between accordion-header pe-3" }
const _hoisted_2 = { class: "lg" }
const _hoisted_3 = { class: "secondary-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TableCell = _resolveComponent("TableCell")!
  const _component_AppBadge = _resolveComponent("AppBadge")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_TableCell, { size: "fluid" }, {
      default: _withCtx(() => [
        _createElementVNode("strong", null, _toDisplayString(_ctx.profileName), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_TableCell, { size: "lg" }, {
      default: _withCtx(() => [
        _createElementVNode("strong", _hoisted_2, _toDisplayString(_ctx.esimId), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_TableCell, { size: "md" }, {
      default: _withCtx(() => [
        _createElementVNode("strong", null, _toDisplayString(_ctx.esimType === "withNumber" ? "eSIM with Number" : "DATA Only"), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_TableCell, { size: "md" }, {
      default: _withCtx(() => [
        _createElementVNode("p", null, [
          _createElementVNode("strong", null, _toDisplayString(_ctx.device), 1)
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_TableCell, { size: "md" }, {
      default: _withCtx(() => [
        _createElementVNode("p", null, [
          _createElementVNode("strong", null, _toDisplayString(_ctx.formatFirstTime), 1),
          _createElementVNode("span", _hoisted_3, "at " + _toDisplayString(_ctx.formatSecondTime) + " GMT", 1)
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_TableCell, { size: "fluid" }, {
      default: _withCtx(() => [
        _createVNode(_component_AppBadge, {
          type: _ctx.status ? 'success' : 'danger',
          size: "md",
          rounded: false
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.status ? "Installed" : "Not Installed"), 1)
          ]),
          _: 1
        }, 8, ["type"])
      ]),
      _: 1
    })
  ]))
}