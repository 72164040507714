<template>
  <div class="logs-wrapper">
    <div
      class="logs-toolbar head d-flex align-items-center justify-content-between padding-inner py-0 pb-5"
    >
      <h2>{{ title }}</h2>

      <div class="d-flex toolbar-content">
        <slot name="toolbar" />
      </div>
    </div>

    <div>
      <slot name="table" />
    </div>
  </div>
</template>

<script>
export default {
  name: "LogsTable",
  props: { title: { type: String, default: "SMS/MMS Logs" } }
};
</script>

<style lang="scss">
.head {
  position: relative;
  z-index: 3;
}

.logs-wrapper {
  .logs-toolbar {
    max-width: 100%;
    @extend %hideScrollBar;

    .toolbar-content {
      padding-right: 20px;
    }
  }
  h2 {
    font-size: 16px;
    margin-right: 1.5em;
    min-width: max-content;
    flex-basis: 110px;
  }

  .pseudo-table--head .pseudo-table-row:after {
    display: none !important;
  }

  .dark-overlay {
    top: 0 !important;
    height: 100% !important;
    min-height: 100vh;
    transform: translate(-50%, 0);
  }
}
</style>
