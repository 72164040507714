
import { defineComponent } from "@vue/runtime-core";
import Card from "@/components/ui/Card/Card.vue";
import AppBadge from "@/components/ui/AppBadge.vue";
import SelectDropdown from "@/components/ui/Dropdown/SelectDropdown.vue";
import AppButton from "@/components/ui/Buttons/AppButton.vue";
import SvgIcon from "@/components/ui/SvgIcon.vue";
import { PropType } from "vue";
import { formatDate } from "@/hooks/formatingDate";

export default defineComponent({
  name: "EsimProfilesPackageBlock",
  components: { SvgIcon, AppButton, SelectDropdown, AppBadge, Card },
  props: {
    planOptions: {
      type: Array as PropType<any>,
      required: true
    },
    activeTo: {
      type: String,
      default: ""
    },
    activeFrom: {
      type: String,
      required: true
    }
  },
  computed: {
    getFormatDateActiveFrom() {
      return formatDate(this.activeFrom);
    }
  },
  methods: {
    planOptionsSelectHandler(id: string | number) {
      this.planOptions.forEach((o: any) => (o.selected = o.id === id));
    }
  }
});
