<template>
  <div class="chat w-100">
    <div v-if="messagesList.length" ref="messages" class="chat-messages">
      <div class="date-group chat-empty">
        <div v-for="(messagesBlock, idx) in messagesList" :key="idx">
          <div class="d-flex justify-content-center mb-2 pb-1">
            <AppBadge>{{ messagesBlock[0] }}</AppBadge>
          </div>
          <Message
            v-for="messageInfo in messagesBlock[1]"
            :key="messageInfo.id"
            :msg-id="messageInfo.msgId"
            :is-me="messageInfo.isMe"
            :msg="messageInfo.msg"
            :time="messageInfo.time"
            :sender-name="messageInfo.senderName"
            :sender-photo="messageInfo.senderPhoto"
            :sender-role="messageInfo.senderRole"
            :actions="messageInfo.actions"
          />
        </div>
      </div>
    </div>
    <div
      v-else
      class="chat-empty d-flex flex-column align-items-center justify-content-center"
    >
      <svg-icon :icon="emptyIcon" />
      <p>{{ emptyText }}</p>
    </div>
    <div class="chat-footer">
      <form
        @submit.prevent="submitHandler"
        @keydown.enter.prevent="submitHandler"
      >
        <AutosizeTextarea
          v-model="message"
          class="chat-field"
          placeholder="Leave a note..."
        />

        <button
          type="submit"
          :data-active="buttonActive && message.length < 250"
          @click="$emit('submit', message)"
        >
          <SvgIcon icon="send" />
        </button>
      </form>
      <span v-if="message.length > 250" class="error">The note field may not be greater than 250 characters</span>
    </div>
  </div>
</template>

<script>
import SimpleBar from "simplebar";
import AutosizeTextarea from "@/components/Form/AutosizeTextarea";
import SvgIcon from "@/components/ui/SvgIcon";
import Message from "@/components/Chat/Message";
import AppBadge from "@/components/ui/AppBadge";
export default {
  name: "Chat",
  components: { AppBadge, Message, SvgIcon, AutosizeTextarea },
  props: {
    messagesList: { type: Array, required: true },
    emptyIcon: { type: String, default: "notes" },
    emptyText: { type: String, default: "There are any notes for this account" }
  },
  emits: {
    submit: null
  },
  data() {
    return {
      scrollbar: null,
      message: "",
      messages: this.messagesList
    };
  },
  computed: {
    scrollArea() {
      return this.$refs.messages;
    },
    buttonActive() {
      return !!this.message.trim().length;
    }
  },
  mounted() {
    const { scrollArea } = this;

    if (scrollArea) {
      this.scrollbar = new SimpleBar(scrollArea, {
        autoHide: false
      });
    }
  },
  methods: {
    updateSubmitHandler() {
      this.message = "";
    }
  }
};
</script>

<style lang="scss">
.chat-empty {
  .icon {
    font-size: 30px;
    color: $border-color;
  }

  p {
    font-size: 1.07rem;
    margin-top: 1.1em;
  }
}
.error {
  color: $color-danger;
}
.chat {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &-footer {
    border-top: 0.5px solid $border-color;
    position: relative;
    z-index: 1;
    &:before {
      content: "";
      width: 1px;
      height: 100%;
      position: absolute;
      top: 0;
      right: 1px;
      background-color: $border-color;
      display: block;
    }
    form {
      display: flex;
      width: 100%;
      .textarea-wrapper {
        width: calc(100% - 90px);
      }

      button[type="submit"] {
        width: 90px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 75px;
        color: $border-color;
        align-self: flex-end;
        font-size: 20px;
        transition: 0.2s;
        &[data-active="true"] {
          color: $color-primary-blue;
        }
      }
    }
  }

  &-messages,
  &-footer {
    padding-left: $content-box-x;
  }

  .chat-empty {
    height: 550px;
    position: relative;
    &:before {
      content: "";
      width: 1px;
      height: 100%;
      position: absolute;
      top: 0;
      right: 1px;
      background-color: $border-color;
      display: block;
    }
  }

  &-messages {
    max-height: 550px;
    overflow: auto;
    position: relative;
    z-index: 999;
    //padding-top: 20px;

    .date-group:last-child .message:last-child {
      margin-bottom: 50px;
    }
  }
  &-field {
    border: none;
    outline: none;
    min-height: 75px;
    width: 100%;
    padding-top: 25px;
    padding-bottom: 20px;
  }
}
</style>
