<template>
  <div class="blocked-wrapper ">
    <div
      class="d-flex align-items-center justify-content-between padding-inner py-0"
    >
      <h2>Blocked Numbers</h2>
      <Btn size="lg" type="danger" @click="toggleModal('numberAddDialog')">
        <div class="d-flex align-items-center">
          <svg-icon icon="plus" class="me-2" /> <span>Block number</span>
        </div>
      </Btn>
    </div>

    <TableView class="no-shadow mt-4" :with-more-menu="true">
      <template #table-head>
        <TableHeadCell
          id="blocked:name"
          name="Contact Name"
          size="lg"
          :on-sort="() => {}"
          sort="a"
        />

        <TableHeadCell id="blocked:number" name="Number" size="md" />
        <TableHeadCell id="blocked:label" name="Label" size="md" />
        <TableHeadCell
          id="blocked:date"
          name="Date blocked"
          size="md"
          :on-sort="() => {}"
          sort="a"
        />

        <TableHeadCell
          id="blocked:for"
          name="Blocked For (Virtual numbers)"
          size="lg"
        />
      </template>
      <template #table-body>
        <TableRow
          v-for="n in 4"
          :key="n"
          :more-menu="[
            {
              text: 'Manage',
              onClick: () => toggleModal('numberManageDialog', true)
            },
            {
              text: 'Remove',
              cls: 'danger',
              onClick: () => toggleModal('numberDeleteDialog', true)
            }
          ]"
        >
          <template #content>
            <TableCell size="lg">
              <UserInfo
                :avatar="require('@/assets/img/sophie.png')"
                name="Hirouki Takeda"
              />
            </TableCell>

            <TableCell size="md">
              <div class="d-flex align-items-center">
                <CountryIcon id="usa" class="me-2" />
                <span>+1 092 6784 887</span>
              </div>
            </TableCell>

            <TableCell size="md">
              <p>Business</p>
            </TableCell>

            <TableCell size="md">
              <p>
                02.05.2020 <br />
                <span class="secondary-text"> 11:38:56 pm GMT</span>
              </p>
            </TableCell>

            <TableCell size="lg">
              <div class="d-flex align-items-center">
                <CountryIcon id="can" class="me-2" />
                <span>+1 092 6784 887 – Home</span>

                <AppBadge
                  v-if="n === 1"
                  size="sm"
                  type="primary"
                  :rounded="true"
                  class="ms-3"
                >
                  +2
                </AppBadge>
              </div>
            </TableCell>
          </template>
        </TableRow>

        <div class="pt-4 pb-5 padding-inner">
          <p class="secondary-text">4 contacts blocked</p>
        </div>
      </template>
    </TableView>
  </div>

  <BlockNumberDialog
    :opened="modals.numberManageDialog"
    @hide="toggleModal(modals.numberManageDialog, false)"
  />

  <DeleteConfirmDialog
    :opened="modals.numberDeleteDialog"
    @hide="toggleModal('numberDeleteDialog', false)"
    @confirm="toggleModal('numberDeleteDialog', false)"
    @cancel="toggleModal('numberDeleteDialog', false)"
  >
    <p>
      <strong><b>Are you sure you want to remove</b></strong>
      <span class=" pt-2 d-inline-flex align-items-center">
        <CountryIcon id="usa" class="d-inline-block" />
        <span class="ms-2">+1 002 6783 190 </span>
      </span>
      <strong class="d-inline-block pb-2">
        <b>contact from Block List?</b>
      </strong>
    </p>
  </DeleteConfirmDialog>

  <Dialog
    id="numberAddDialog"
    :opened="modals.numberAddDialog"
    size="xs"
    :use-header="false"
    class="ove"
    @hide="toggleModal('numberAddDialog', false)"
  >
    <div class="text-center">
      <h4>Add number to block list</h4>
      <p class="secondary-text mt-2">
        Numbers in block list won’t be able to reach you with calls or messages.
      </p>
    </div>

    <div class="mt-4 pt-3">
      <PhoneInput id="addToBlockNumber" v-model="number" label="Number" />
    </div>

    <div class="mt-3 pt-2">
      <Btn
        type="secondary"
        size="xl"
        @click="toggleModal('numberAddDialog', false)"
      >
        Continue
      </Btn>
      <Btn
        type="default-transparent"
        size="xl"
        @click="toggleModal('numberAddDialog', false)"
      >
        Cancel
      </Btn>
    </div>
  </Dialog>
</template>

<script>
import Btn from "@/components/ui/Buttons/Button";
import SvgIcon from "@/components/ui/SvgIcon";
import TableView from "@/components/Table/TableView";
import TableHeadCell from "@/components/Table/TableHeadCell";
import TableRow from "@/components/Table/TableRow";
import TableCell from "@/components/Table/TableCell";
import UserInfo from "@/components/UserInfo/UserInfo";
import CountryIcon from "@/components/ui/Country/CountryIcon";
import AppBadge from "@/components/ui/AppBadge";
import BlockNumberDialog from "@/components/User/BlockUser/BlockNumberManageDialog";
import DeleteConfirmDialog from "@/components/ui/Modal/Dialog/instances/DeleteConfirmDialog";
import Dialog from "@/components/ui/Modal/Dialog/Dialog";
import PhoneInput from "@/components/ui/Modal/Dialog/instances/PhoneInput";
export default {
  name: "BlockedNumbersList",
  components: {
    PhoneInput,
    Dialog,
    DeleteConfirmDialog,
    BlockNumberDialog,
    AppBadge,
    CountryIcon,
    UserInfo,
    TableCell,
    TableRow,
    TableHeadCell,
    TableView,
    SvgIcon,
    Btn
  },
  data() {
    return {
      number: "",
      modals: {
        numberManageDialog: false,
        numberDeleteDialog: false,
        numberAddDialog: false
      }
    };
  },

  methods: {
    toggleModal(id, status = true) {
      Object.keys(this.modals).forEach(key => {
        this.modals[key] = status ? key === id : status;
      });
    }
  }
};
</script>

<style scoped></style>
