<template>
  <div class="connected-social-wrapper w-100 d-flex">
    <ul v-if="socials.length" class="connected-social-list w-100 pt-2 mt-1">
      <li
        v-for="{ id, name, connectionDate, user, image } in socials"
        :key="id"
        class="connected-social-list--item d-flex align-items-center"
      >
        <div class="social-hamburger"><svg-icon icon="hamburger" /></div>

        <div class="d-flex align-items-center social-connected-block">
          <div class="general d-flex align-items-center">
            <div class="social-img">
              <img :src="image" :alt="name" />
            </div>
            <div class="social-connected-block--info">
              <h4>{{ name }}</h4>
              <span class="secondary-text">{{ connectionDate }}</span>
            </div>
          </div>

          <div class="social-connected-block-username">{{ user }}</div>
        </div>

        <Btn
          type="danger-transparent"
          size="md"
          class="ms-auto"
          @click="remove(id)"
        >
          <svg-icon icon="trash" />
        </Btn>
      </li>
    </ul>

    <div
      v-else
      class="connected-social-empty d-flex  align-items-center justify-content-center w-100"
    >
      <div>
        <img class="mx-auto d-block" :src="emptyImage" alt="" />
        <strong class="text-center mt-3">
          User hasn’t associated his Telesim account <br />
          with soical networks.
        </strong>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from "@/components/ui/SvgIcon";
import Btn from "@/components/ui/Buttons/Button";
export default {
  name: "ConnectedSocial",
  components: { Btn, SvgIcon },
  data() {
    return {
      emptyImage: require("@/assets/img/social/socials-group.svg"),
      socials: [
        {
          id: "fb",
          name: "Facebook",
          connectionDate: "14.04.2020 at 2:40 am",
          user: "@sophieturner",
          image: require(`@/assets/img/social/fb.svg`)
        },
        {
          id: "appl",
          name: "Apple",
          connectionDate: "14.04.2020 at 2:40 am",
          user: "@sophie",
          image: require(`@/assets/img/social/appl.svg`)
        },
        {
          id: "inst",
          name: "Instagram",
          connectionDate: "14.04.2020 at 2:40 am",
          user: "@sophieturner",
          image: require(`@/assets/img/social/inst.svg`)
        }
      ]
    };
  },

  methods: {
    remove(id) {
      this.socials = this.socials.filter(s => s.id !== id);
    }
  }
};
</script>

<style scoped lang="scss">
.social-hamburger {
  padding-right: 20px;
}

.connected-social-list--item {
  padding-top: 20px;
  padding-bottom: 20px;
  position: relative;
  &:not(:last-child) {
    &:before {
      content: "";
      width: calc(100% - (20px + 1rem));
      height: 1px;
      bottom: 0;
      right: 0;
      position: absolute;
      display: block;
      background: $border-color;
    }
  }
}

.social-connected-block {
  //h4 {
  //  font-size: 1.07em;
  //}
  .social-img {
    width: 2.857em;
    height: 2.857em;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 1em;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .general {
    width: 230px;
  }
}
</style>
